
import { Options, Vue } from 'vue-class-component';
import { BugManagementClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})


export default class BugReportList extends Vue {

    reports: OM.BugReportDetailVM[] = [];
    
    created() {
        this.init();
    }

    init() {
        BugManagementClient.getAllReports()
        .then(x => {
            this.reports = x;
        })
    }
}
